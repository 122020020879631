<template>
  <Form
    :submit="onSubmit"
    :initialValues="initialValues"
  >
    <div class="form-narrow">
      <div class="form-row">
        <TextField name="name" label="name*" :validate="required" />
        <TextField name="code" label="code*" :validate="required" />
      </div>
      <div class="form-row">
        <TextField name="apiEndpoint" label="api endpoint*" :validate="[required, safeUrl]" />
      </div>
      <ModalFooter :footer="footer" :tertiary="cancel" />
    </div>
  </Form>
</template>

<script>
  import ModalNavigation from "@/mixins/ModalNavigation";
  import ValidatorMixin from "@/components/form/ValidatorMixin";
  import Form from "@/components/form/Form";
  import ModalFooter from "@/components/ui/modals/ModalFooter";
  import TextField from "@/components/form/TextField";

  export default {
    name: "ApplicationForm",
    components: {
      ModalFooter,
      Form,
      TextField,
    },
    mixins: [
      ModalNavigation,
      ValidatorMixin,
    ],
    props: {
      onSubmit: {
        type: Function,
        required: true,
      },
      initialValues: {
        type: Object,
        default: () => ({})
      },
      loading: {
        type: Boolean,
        required: false,
        default: false,
      },
    },
    data(){
      return {
        footer: {
          primaryButton: 'save',
          tertiaryButton: 'cancel'
        },
      }
    },
    methods: {
      cancel(e) {
        e.preventDefault();
        this.close();
      }
    },
  }
</script>

<style scoped>

</style>
